import React from "react";
import "./ScoringDiff.css";

import SourceAgnostic from "../../../assets/image/svg/diff/database.svg";
import GlobaCoverage from "../../../assets/image/svg/diff/multilingual.svg";
import EasyIntegration from "../../../assets/image/svg/diff/easy_integration.svg";
import Turnkey from "../../../assets/image/svg/diff/turnkey.svg";
const ScoringDiff = () => {
  return (
    <section className="diff2-section-1">
      <div className="diff2-section-2">
        <div>
          <p className="section-header">Why Genify?</p>
          <h2 className="section-h2">
            Your partner to unlock value in transaction data.
          </h2>
        </div>
        <div className="diff-grid">
          {" "}
          {/*grid*/}
          <div>
            <div>
              <img
                src={GlobaCoverage}
                style={{ height: "60px", position: "relative" }}
                alt="global coverage"
              />
            </div>{" "}
            {/*img */}
            <div></div> {/*spacer */}
            <h4 className="diff-section-h4 mt-4">Continuous upgrades</h4>
            <p className="diff-section-p">
              Your subscription gives access to new features, sourced directly
              from our customers.
            </p>
          </div>
          <div>
            <img
              src={EasyIntegration}
              style={{ height: "60px", position: "relative" }}
              alt="easy integration"
            />{" "}
            {/*img */}
            <div></div> {/*spacer */}
            <h4 className="diff-section-h4 mt-4">Ease of Integration</h4>
            <p className="diff-section-p">
              A single API endpoint, 5 enrichment fields.
            </p>
          </div>
          <div>
            <img
              src={SourceAgnostic}
              style={{ height: "60px", position: "relative" }}
              alt="source agnostic"
            />{" "}
            {/*img */}
            <div></div> {/*spacer */}
            <h4 className="diff-section-h4 mt-4">Secure Data</h4>
            <p className="diff-section-p">
              Our servers are ISO 27001 certified, and our data usage is
              compliant with GDPR.
            </p>
          </div>
          <div>
            <img
              src={Turnkey}
              style={{ height: "60px", position: "relative" }}
              alt="turnkey"
            />{" "}
            {/*img */}
            <div></div> {/*spacer */}
            <h4 className="diff-section-h4 mt-4">On Premises or Cloud</h4>
            <p className="diff-section-p">
              Our API runs on your premises or in the cloud, thanks to a hybrid
              build since Day 1.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ScoringDiff;
