import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import iconF1 from "../assets/image/svg/database.svg";
import iconF2 from "../assets/image/svg/no-data.svg";
import iconF3 from "../assets/image/svg/multilingual.svg";
import iconF6 from "../assets/image/svg/custom.svg";
const CreditScoringFeatures = () => {
  return (
    <div className="feature-section pb-4 pb-lg-13 " style={{ paddingTop: "" }}>
      <Container>
        <Row>
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="800"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <div className="icon-wrap">
                  <img src={iconF1} alt="machine learning based" />
                </div>
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">
                    Machine Learning-Based
                  </h5>
                  <p className="gr-text-9 white">
                    Running with multiple machine learning models at its core.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="2100"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF6} alt="customizable" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">Customizable</h5>
                  <p className="gr-text-9 white">
                    Digital scorecards tailored to your specific risk profile
                    metrics.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1 white"
              data-aos="fade-right"
              data-aos-duration="1400"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF3} alt="real time" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">Real-Time</h5>
                  <p className="gr-text-9 white">
                    Instant loan approval or refusal based on your specified
                    risk appetite.
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col sm="6" lg="4" className="mb-8">
            <div
              className="feature-widget-1"
              data-aos="fade-right"
              data-aos-duration="1100"
            >
              <div className="media flex-column pr-8 pr-lg-7 pr-xl-12">
                <img src={iconF2} alt="low to no data requirements" />
                <div className="media-body mt-10">
                  <h5 className="gr-text-7 mb-7 white">
                    Low-to-No Data Requirements
                  </h5>
                  <p className="gr-text-9 white">
                    Ready to use with your data on day 1 thanks to pre-trained
                    models.
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CreditScoringFeatures;
