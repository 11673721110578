import React from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import mobile from "../assets/image/svg/mobile.svg";

const OtherCTA = () => {
  return (
    <div className="cta-section-1 py-12 pt-20 pb-20 pb-lg-30  mb-20">
      <Container>
        <Row className="align-items-center ">
          <Col lg="6" className="order-lg-2 offset-xl-1">
            <div className="section-title cta-text pl-lg-12">
              <h2 className="gr-text-2 mb-7" style={{ fontSize: "2rem" }}>
                Start Testing Today
              </h2>
              <p className="gr-text-9">
                Get in touch for a demo with our technical sales team.
              </p>
            </div>

            <div
              className="cta-btn d-flex flex-column flex-sm-row mt-5 mt-lg-0 ml-lg-10 p-sm-4"
              style={{ lineHeight: "1.2", maxWidth: "70vw" }}
            >
              <Link
                to="/contact-us"
                className="btn btn-primary  gr-hover-y with-icon mr-sm-7 mb-6 mb-sm-0"
                style={{
                  backgroundColor: "#ff565c",
                  border: "0",
                  lineHeight: "1.2",
                }}
              >
                Request Demo{" "}
                <i className="icon icon-tail-right font-weight-bold"></i>
              </Link>
              <Link
                to="/txn-data-gui"
                rel="noopener noreferrer"
                className="btn btn-primary with-icon gr-hover-y "
                style={{
                  backgroundColor: "rgb(74, 99, 165)",
                  border: "0",
                  lineHeight: "1.2",
                }}
              >
                Try Live Categorization{" "}
                <i className="icon icon-tail-right font-weight-bold"></i>
              </Link>
            </div>
          </Col>
          <Col lg="5" xl="5" className=" order-lg-1">
            <div
              className="test-landing"
              data-aos="fade-left"
              data-aos-duration="750"
              data-aos-delay="500"
              style={{ position: "relative" }}
            >
              <object
                className=" main-object"
                data={mobile}
                alt=""
                aria-labelledby="main object"
                style={{
                  maxHeight: "70vh",
                  position: "absolute",
                  right: "-4vw",
                  top: "-38vh",
                }}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OtherCTA;
