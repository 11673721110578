import React, { useEffect, useState } from "react";

import { Container, Row, Col, Button, Collapse } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import CreditScoringShowCase from "../components/CreditScoringShowCase";
import Differentiation from "../components/Differentiation";
import CreditScoringFeatures from "../components/CreditScoringFeatures";
import TransDashboard from "../components/TransDashboard";

import objectHero from "../assets/extra/Products/credit-scoring.svg";

import ogImage from "../assets/extra/global/link-logo-white.png";

import Seo from "../components/Seo.js";
import TransCTA from "../components/TransCTA";
import CreditScoringDemo from "../components/APIDemos/CreditScoringDemo";
import ScoringUsecases from "../components/Usecasesv2/ScoringUsecases/ScoringUsecases";
import ScoringDiff from "../components/Differentiationv2/ScoringDiff/ScoringDiff";
import { Link } from "gatsby";
import CreditScoringDemoV2 from "../components/APIDemos/CreditScoringDemoV2";
import APIDemo from "../components/APIDemos/APIDemo";
import OtherCTA from "../components/OtherCTA";
import WhyGenify from "../components/Differentiationv2/WhyGenify";

const CreditScoring = () => {
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        url: "https://genify.ai/",
        path: "/credit-scoring-api",
        page_type: "productPage",
        event: "page_view",
        title: "Credit Scoring API ",
      });
    }
  }, []);

  const [openItem, setOpenItem] = useState(1);
  const imgurl = `https://genify.ai${ogImage}`;

  return (
    <>
      <Seo
        title="Credit Scoring API | Genify"
        description="Credit scoring API to score loan applicants effortlessly using traditional & alternative data. Discover now Genify's Credit Scoring API."
        image={imgurl}
        url="https://www.genify.ai/credit-scoring-api/"
      />

      <PageWrapper
        headerConfig={{
          theme: "light",
          align: "right",
          variant: "primary",
          isFluid: true,
          source: "genify",
          button: "null",
          path: "productPage",
        }}
        footerConfig={{
          style: "style1",
        }}
      >
        <div
          className=" dark-mode-texts pt-13 pt-md-25 pb-13 pb-md-25 mx-md-6 rounded-10 "
          style={{ backgroundColor: "#fff" }}
        >
          <>
            {/* <!-- Hero Area --> */}
            <div
              className="hero-area position-relative  pt-29 pt-lg-33  pb-lg-35 pb-lg-35"
              style={{ backgroundColor: "#fff" }}
            >
              <Container className="landing-large">
                <Row className="justify-content-center  align-items-center">
                  <Col
                    lg="6"
                    md="6"
                    sm="12"
                    xs="12"
                    className="order-lg-2 mt-9 mt-lg-0 pl-md-25"
                    data-aos="fade-right"
                    data-aos-duration="500"
                    data-aos-once="true"
                  >
                    <div
                      className="hero-content dark-mode-texts landing-text"
                      style={{ width: "90%" }}
                    >
                      <h1
                        className="title gr-text-2 mb-7"
                        style={{ color: "rgb(74, 99, 165)" }}
                      >
                        Credit Scoring API{" "}
                      </h1>

                      <p
                        className="gr-text-9 pr-5 pl-0 pr-xl-11 py-10"
                        style={{
                          textAlign: "left",
                        }}
                      >
                        With our credit scoring API, you can score your loan
                        applicants effortlessly with hosted and customizable
                        credit scoring models. Offer financing to unbanked
                        individuals assessed via alternative data sources and
                        meeting your risk requirements.
                      </p>
                      <div
                        className="hero-btn pb-10"
                        style={{ zIndex: "9999", textAlign: "left" }}
                      >
                        <Link to="/contact-us/#contactform">
                        <Button
                          // href="contact-us/#contactform"
                          className="gr-hover-y"
                          style={{ backgroundColor: "#ff565b", border: "0" }}
                          onClick={() =>
                            window.dataLayer.push({
                              page_type: "productPage",
                              event: "contact_us",
                            })
                          }
                        >
                          Request a Demo{" "}
                        </Button>
                        </Link>
                      </div>
                    </div>
                  </Col>
                  <Col
                    lg="6"
                    md="6"
                    className="order-lg-1 order-sm-1 order-xs-1  "
                  >
                    <div
                      className="test-landing"
                      data-aos="fade-left"
                      data-aos-duration="750"
                      data-aos-delay="500"
                    >
                      <object
                        className=" main-object zoom-product-1-trans"
                        data={objectHero}
                        aria-labelledby="main object"
                        alt=""
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </>

          <div style={{ backgroundColor: "#fff" }} className="pt-10 pb-10">
            <Container>
              <Row className="align-items-center">
                <Col
                  md="10"
                  lg="12"
                  xl="6"
                  className="offset-xl-1 drawer mt-9 mt-lg-0 order-lg-2 order-md-2 order-sm-2 order-xs-2"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                  // style={{ maxWidth: "40vw" }}
                >
                  <div
                    className="accordion pl-9 pr-8 rounded-10  border-top-5"
                    id="accordionExample"
                    style={{
                      backgroundColor: "rgb(74, 99, 165)",
                      borderBlockColor: "#ff565b",
                    }}
                  >
                    <div className="border-bottom overflow-hidden">
                      <div className="mb-0 border-bottom-0" id="heading2-1">
                        <button
                          className="btn-reset gr-text-6 font-weight-bold text-left  px-0 py-8 accordion-trigger arrow-icon w-100 collapsed"
                          type="button"
                          aria-controls="collapse2-1"
                          onClick={() => setOpenItem(1)}
                          aria-expanded={openItem === 1}
                          style={{ color: "#fff" }}
                        >
                          Digital Footprint Credit Scorecard
                        </button>
                      </div>

                      <Collapse in={openItem === 1}>
                        <div>
                          <div className="gr-color-white-opacity-7 mt-n3 gr-text-10 pb-8 pr-8">
                            Enhance your borrowers’ credit score reliability
                            with alternative data for credit scoring, including
                            digital footprint and behavioral information
                            gathered through smartphones and online platforms
                            like e-commerce and ride-hailing.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="border-bottom overflow-hidden">
                      <div className="mb-0 border-bottom-0" id="heading2-2">
                        <button
                          className="btn-reset gr-text-6 font-weight-bold text-left text-white px-0 py-8 accordion-trigger arrow-icon w-100 collapsed"
                          type="button"
                          data-target="#collapse2-2"
                          onClick={() => setOpenItem(2)}
                          aria-expanded={openItem === 2}
                        >
                          Tailored Risk Profile
                        </button>
                      </div>

                      <Collapse in={openItem === 2}>
                        <div>
                          <div className="gr-color-white-opacity-7 mt-n3 gr-text-10 pb-8 pr-8">
                            Set a score threshold based on your risk appetite
                            for automatic applicant acceptance and rejection,
                            calibrate the credit scoring model to your own
                            borrower base, and add filtering rules.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="border-bottom overflow-hidden">
                      <div className="mb-0 border-bottom-0" id="heading2-3">
                        <button
                          className="btn-reset gr-text-6 font-weight-bold text-left text-white px-0 py-8 accordion-trigger arrow-icon w-100"
                          type="button"
                          data-target="#collapse2-3"
                          onClick={() => setOpenItem(3)}
                          aria-expanded={openItem === 3}
                        >
                          Credit Scorecard Dashboard
                        </button>
                      </div>

                      <Collapse in={openItem === 3}>
                        <div>
                          <div className="gr-color-white-opacity-7 mt-n3 gr-text-10 pb-8 pr-8">
                            Review applicant profiles and scorecards one by one
                            in our intuitive dashboard, for edge cases or spot
                            checks to the scoring model.
                          </div>
                        </div>
                      </Collapse>
                    </div>
                  </div>
                </Col>
                <Col
                  xs="12"
                  sm="12"
                  md="12"
                  lg="5"
                  className="order-lg-1 order-md-1 order-sm-1 order-xs-1 mt-sm-20"
                >
                  <div className=" mt-12 mt-md-0">
                    <div className="info-label">Robust Credit Scoring</div>
                    <h2
                      className="gr-text-4 mb-7 justify-content-center"
                      style={{ color: "rgb(74, 99, 165)" }}
                    >
                      Leverage the power of alternative data for credit scoring.
                    </h2>
                    <p
                      className="gr-text-9 gr-text-color-opacity"
                      style={{
                        lineHeight: "1.8",
                        letterSpacing: "-0.2px",
                        // fontSize: '0.9rem'
                      }}
                    >
                      Genify's credit scoring model and system use both
                      traditional and alternative data sources to produce a
                      comprehensive scorecard, while adhering to your risk
                      appetite, so the unbanked or underbanked are not left out.
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div
          className="inner-banner pt-20 pb-11  border-top-5"
          style={{ backgroundColor: "rgb(74, 99, 165)" }}
        >
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2
                    className="title gr-text-2 mb-9"
                    style={{ color: "#fff" }}
                  >
                    Features
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
          <CreditScoringFeatures />
        </div>

        <ScoringUsecases />

        {/* <ScoringDiff /> */}
        <WhyGenify />
        {/* <div className="inner-banner pt-29 pb-md-11 bg-default-1">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9" style={{}}>
                    Genify Dashboard
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <TransDashboard />
        <div className="inner-banner pt-29 pb-md-11 bg-default-1">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9" style={{}}>
                    Showcase
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <CreditScoringShowCase /> */}
        {/* <div
          className="inner-banner pt-29  bg-default-1 pb-25 border-top-5"
          style={{ backgroundColor: "rgb(74, 99, 165)" }}
        >
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2
                    className="title gr-text-2 mb-9"
                    style={{ color: "#fff" }}
                  >
                    Why Genify?
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
          <Differentiation />
        </div> */}
        <div
          className="inner-banner pt-20 pb-20"
          style={{ backgroundColor: "rgb(245, 247, 250)" }}
        >
          <Container>
            <Row className="justify-content-center pt-5">
              <Col lg="9" xl="8">
                <div className="px-md-15 text-center">
                  <h2 className="title gr-text-2 mb-9" style={{}}>
                    How Does the Credit Scoring API Work?
                  </h2>
                </div>
              </Col>
            </Row>
          </Container>
          {/* <TxnApiDemo /> */}
          <APIDemo selected="credit" exclusive />
        </div>
        {/* <OtherCTA /> */}
      </PageWrapper>
    </>
  );
};

export default CreditScoring;
