import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import CreditScoringDemoV2 from "./CreditScoringDemoV2";
import ProdRecomDemoV2 from "./ProdRecomDemoV2";
import TxnApiDemoV2 from "./TxnApiDemov2";
import { Link } from "gatsby";

const APIDemo = ({ selected, exclusive = false }) => {
  const [currentDemo, setCurrentDemo] = useState(selected);
  const [demoOptions, setDemoOptions] = useState(null);

  const getComponent = (demo) => {
    switch (demo) {
      case "txn":
        return TxnApiDemoV2();
      case "credit":
        return CreditScoringDemoV2();
      case "recom":
        return ProdRecomDemoV2();
      default:
        return TxnApiDemoV2();
    }
  };

  useEffect(() => {
    if (exclusive) setDemoOptions(SelectedDemo());
    else
      setDemoOptions(
        <>
          {TxnDemo()}
          {CreditScoringDemo()}
          {ProdRecomDemo()}
        </>
      );
  }, [selected, currentDemo]);

  const SelectedDemo = () => {
    switch (selected) {
      case "txn":
        return TxnDemo();
      case "credit":
        return CreditScoringDemo();
      case "recom":
        return ProdRecomDemo();
      default:
        return (
          <>
            {TxnDemo()}
            {CreditScoringDemo()}
            {ProdRecomDemo()}
          </>
        );
    }
  };

  return (
    <>
      {/* <!-- Category section --> */}
      <div className="category-section pl-lg-20 pt-12 pb-6 pt-lg-14 pb-lg-24  ">
        <Link to="/contact-us" className="gr-text-color gr-hover-text-white">
          <Button
            className="gr-hover-y mb-10 p-2 ml-4 ml-sm-0"
            style={{ backgroundColor: "#ff565b", border: "0" }}
          >
            Talk to us
          </Button>
        </Link>
        <Row>
          <Col className="mobile-fullwidth" style={{ maxWidth: "50em" }}>{demoOptions}</Col>
          <Col>{getComponent(currentDemo)}</Col>
        </Row>
      </div>
    </>
  );

  function ProdRecomDemo() {
    const isSelected = currentDemo === "recom";
    return (
      <Row>
        <Col
          sm="10"
          md="10"
          lg="10"
          data-aos="fade-right"
          data-aos-duration="1100"
        >
          <Button
            onClick={() => setCurrentDemo("recom")}
            style={
              isSelected
                ? { cursor: "default" }
                : {
                    backgroundColor: "#9cb2bd",
                    border: "0"
                  }
            }
            className="d-flex justify-content-between rounded-10 px-9 pt-8 pb-8 align-items-center mb-11 category-card gr-hover-y"
          >
            <div className="texts light-mode-texts relative">
              {!isSelected && (
                <i className="ml-auto icon icon-small-down absolute top-0 right-0 fa-2x"></i>
              )}

              <h3 className="card-title gr-text-7-card font-weight-bold">
                Recommendation API
              </h3>
              <p
                className={`api-card-text mb-4 transition-all ${
                  !isSelected ? "mobile" : ""
                }`}
              >
                Our API recommends the next-best action or next-best product
                individually for each user.
              </p>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  window.open(
                    "https://docs.pfm.genify.ai/pfm-suite/v1/restricted-access/recommendation-api",
                    "_blank"
                  );
                }}
                className="transition-all gr-text-11-card gr-hover-text-white overflow-hidden flex gap-2 
                align-items-center cursor-pointer"
                style={{
                  color: "#c5d4e8",
                  maxHeight: isSelected ? "30px" : "0",
                }}
              >
                <div className="">View API documentation </div>
                <i className="fas fa-book gr-text-8-card text-white"></i>
              </div>
            </div>
          </Button>
        </Col>
      </Row>
    );
  }

  function CreditScoringDemo() {
    const isSelected = currentDemo === "credit";
    return (
      <Row>
        <Col
          sm="10"
          md="10"
          lg="10"
          data-aos="fade-right"
          data-aos-duration="1100"
        >
          <Button
            onClick={() => setCurrentDemo("credit")}
            style={
              isSelected
                ? { cursor: "default" }
                : {
                    backgroundColor: "#9cb2bd",
                    border: "0"
                  }
            }
            className="d-flex justify-content-between rounded-10 px-9 pt-8 pb-8 align-items-center mb-11 category-card gr-hover-y"
          >
            <div className="texts light-mode-texts relative">
              {!isSelected && (
                <i className="ml-auto icon icon-small-down absolute top-0 right-0 fa-2x"></i>
              )}
              <h3 className="card-title gr-text-7-card font-weight-bold">
                Credit Scoring API
              </h3>
              <p
                className={`api-card-text mb-4 transition-all ${
                  !isSelected ? "mobile" : ""
                }`}
              >
                Our API lets you collect relevant data points from your users’
                phone and apply your credit decisioning policy.
              </p>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  window.open("https://docs.ezloan.genify.ai/", "_blank");
                }}
                className="transition-all gr-text-11-card gr-hover-text-white overflow-hidden flex gap-2 
                align-items-center cursor-pointer"
                style={{
                  color: "#c5d4e8",
                  maxHeight: isSelected ? "30px" : "0",
                }}
              >
                <div className="">View API documentation </div>
                <i className="fas fa-book gr-text-8-card text-white"></i>
              </div>
            </div>
          </Button>
        </Col>
      </Row>
    );
  }

  function TxnDemo() {
    const isSelected = currentDemo === "txn";
    return (
      <Row>
        <Col
          sm="10"
          md="10"
          lg="10"
          data-aos="fade-right"
          data-aos-duration="1100"
        >
          <Button
            onClick={() => setCurrentDemo("txn")}
            style={
              isSelected
                ? { cursor: "default" }
                : {
                    backgroundColor: "#9cb2bd",
                    border: "0",
                  }
            }
            className="d-flex justify-content-between rounded-10 px-9 pt-8 pb-8 align-items-center mb-11 category-card gr-hover-y"
          >
            <div className="texts light-mode-texts relative">
              {!isSelected && (
                <i className="ml-auto icon icon-small-down absolute top-0 right-0 fa-2x"></i>
              )}

              <h3 className="card-title gr-text-7-card  font-weight-bold">
                Transaction Categorization API
              </h3>
              <p
                className={`api-card-text mb-4 transition-all ${
                  !isSelected ? "mobile" : ""
                }`}
              >
                Our API enriches transaction data with five fields: category,
                merchant logo, clean merchant name, merchant website, and carbon
                footprint.
              </p>
              <div
                onClick={(e) => {
                  e.preventDefault();
                  window.open("https://docs.pfm.genify.ai/", "_blank");
                }}
                className="transition-all gr-text-11-card gr-hover-text-white overflow-hidden flex gap-2
                align-items-center cursor-pointer"
                style={{
                  color: "#c5d4e8",
                  maxHeight: isSelected ? "30px" : "0",
                }}
              >
                <div className="">View API documentation </div>
                <i className="fas fa-book gr-text-8-card text-white"></i>
              </div>
            </div>
          </Button>
        </Col>
      </Row>
    );
  }
};

export default APIDemo;
