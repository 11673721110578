import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import EzloanLogo from "../../assets/image/ezloan_logo/PNG/Ezloan-api.png";

const CreditScoringDemo = () => {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  }));
  const handleResponseSubmit = () => {
    var twoToneButton = document.querySelector(".twoToneButton");
    var refreshBtn = document.querySelector(".credit-result-button");

    var terminalInputResponse = document.querySelector(".credit-result");
    refreshBtn.style.width = "10em";

    twoToneButton.innerHTML = "Running";
    twoToneButton.classList.add("spinning");
    setTimeout(function() {
      twoToneButton.classList.remove("spinning");
      terminalInputResponse.classList.remove("hidden");
      terminalInputResponse.classList.remove("hidden");
      twoToneButton.classList.add("hidden");
      twoToneButton.disabled = true;
    }, 2000);
  };
  function FormRow1() {
    return (
      <React.Fragment>
        <Grid item xs={12} md={12} lg={12} style={{ margin: "auto" }}>
          <Paper
            className={classes.paper}
            style={{ backgroundColor: "rgb(255, 86, 91)", color: "#fff" }}
          >
            <b>
              <div>Credit Score</div> 740
            </b>
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
  function FormRow2() {
    return (
      <React.Fragment>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            className={classes.paper}
            style={{ backgroundColor: "#4a63a5", color: "white" }}
          >
            <div>Full Name:</div>
            John Doe
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            className={classes.paper}
            style={{ backgroundColor: "#4a63a5", color: "white" }}
          >
            <div>Age:</div>
            23{" "}
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            className={classes.paper}
            style={{ backgroundColor: "#4a63a5", color: "white" }}
          >
            <div>Area:</div>
            Rehab
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
  function FormRow3() {
    return (
      <React.Fragment>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            className={classes.paper}
            style={{ backgroundColor: "#4a63a5", color: "white" }}
          >
            <div>Estimated Income:</div>
            7000EGP
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            className={classes.paper}
            style={{ backgroundColor: "#4a63a5", color: "white" }}
          >
            <div>National ID:</div> 98764758476354
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            className={classes.paper}
            style={{ backgroundColor: "#4a63a5", color: "white" }}
          >
            <div>Certainty:</div> 80%
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
  function FormRow4() {
    return (
      <React.Fragment>
        <Grid item xs={12} md={12} lg={12}>
          <Paper
            className={classes.paper}
            style={{ backgroundColor: "#4a63a5", color: "white" }}
          >
            <div>Monthly Spending Capacity:</div> 3000{" "}
          </Paper>
        </Grid>

        <Grid item xs={12} md={12} lg={12}>
          <Paper
            className={classes.paper}
            style={{ backgroundColor: "#4a63a5", color: "white" }}
          >
            <div>Credit Score Classification:</div> Fair
          </Paper>
        </Grid>
      </React.Fragment>
    );
  }
  const classes = useStyles();

  return (
    <>
      <div className="gr-timeline-wrapper gr-flex-all-center pb-30">
        <div
          className="single-timeline-feature text-center px-6 px-md-9"
          data-aos="zoom-in"
          data-aos-duration="900"
          style={{ display: "grid" }}
        >
          <div
            className="count circle-lg  gr-text-4 mx-auto mb-12 text-white"
            style={{ backgroundColor: "rgb(74, 99, 165)" }}
          >
            <span>1</span>
          </div>
          <div className="content px-xl-7">
            <h3 className="title gr-text-5 mb-6">Onboard Borrower</h3>
            <p className="gr-text-9"> </p>
          </div>
        </div>
        <div
          className="single-timeline-feature text-center px-6 px-md-9"
          data-aos="zoom-in"
          data-aos-duration="900"
          data-aos-delay="400"
        >
          <div
            className="count circle-lg  gr-text-4 mx-auto mb-12 text-white"
            style={{ backgroundColor: "rgb(74, 99, 165)" }}
          >
            <span>2</span>
          </div>
          <div className="content px-xl-7">
            <h3 className="title gr-text-5 mb-6">Specify Credit Product</h3>
            <p className="gr-text-9"> </p>
          </div>
        </div>
        <div
          className="single-timeline-feature text-center px-6 px-md-9"
          data-aos="zoom-in"
          data-aos-duration="900"
          data-aos-delay="800"
          style={{ display: "grid" }}
        >
          <div
            className="count circle-lg  gr-text-4 mx-auto mb-12 text-white"
            style={{ backgroundColor: "rgb(74, 99, 165)", display: "grid" }}
          >
            <span>3</span>
          </div>
          <div className="twoToneCenter">
            <button
              style={{ zIndex: "999" }}
              className="credit-result-button twoToneButton gr-hover-y gr-text-6 content px-xl-7 "
              onClick={handleResponseSubmit}
            >
              Get Credit Scorecard
            </button>
          </div>
        </div>
      </div>
      <div class="credit-result card-2 middle hidden">
        <div class="front">
          <img src={EzloanLogo} alt="ezloan logo" />
          <div
            className="centered"
            style={{ textAlign: "center", fontSize: "5vh", fontWeight: "bold" }}
          >
            Credit Score
          </div>
          <div
            className="centered-2"
            style={{ textAlign: "center", fontSize: "4vh", fontWeight: "bold" }}
          >
            740
          </div>
        </div>
        <div class="back">
          <div class="back-content middle">
            <div class="inner-back">
              <Paper style={{ backgroundColor: "#4A63A5 ", margin: "auto" }}>
                <Grid
                  container
                  item
                  xs={12}
                  spacing={3}
                  style={{ margin: "auto" }}
                >
                  <FormRow1 />
                </Grid>
                <Grid container item xs={12} spacing={3} className="card-rows">
                  <FormRow2 />
                </Grid>
                <Grid container item xs={12} spacing={3} className="card-rows">
                  <FormRow3 />
                </Grid>
                <Grid container item xs={12} spacing={3} className="card-rows">
                  <FormRow4 />
                </Grid>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreditScoringDemo;
